import React from 'react';

function LoomVideo({ videoId }) {
  const loomUrl = `https://www.loom.com/embed/${videoId}?hide_title=true&hide_share=true&hideEmbedTopBar=true`;
  return (
    <section className="featured-video">
        <div className="container-large">
            <div className="loom-iframe-wrapper">
                <iframe
                    src={loomUrl}
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    title="Loom Video"
                ></iframe>
            </div>
        </div>
    </section>
  );
};
    
export default LoomVideo;